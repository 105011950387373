<template>
  <b-card no-body>
      <b-card>
        <b-card-body class="p-0">
          
          <h3>Inspection List</h3>
          <br>
          <b-row>
            
            <b-col cols="2">
            <b-form-group
              label="From Date"
              label-for="From Date"
            >
              <b-form-datepicker
                id="from-date"
                label="label"
                v-model="startDate"
                class="mb-1"
                placeholder="Start Date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="To Date"
              label-for="To Date"
            >
              <b-form-datepicker
                id="to-date"
                label="label"
                v-model="endDate"
                class="mb-1"
                placeholder="End Date"
              />
            </b-form-group>
          </b-col>

          <b-col cols="2">
              <b-form-group label="Vehicle" label-for="vehicle">
                <v-select
                                v-model="vehicle"
                                label="label"
                                :options="option"
                                @input="onChange()"
                                placeholder="Select Vehicle"
                                multiple
                            />
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-form-group label="Driver" label-for="driver">
               
                <v-select
                                v-model="driver"
                                label="label"
                                :options="driver_options"
                                @input="onChangeDriver()"
                                placeholder="Select Driver"
                            />
  
              </b-form-group>
            </b-col>
  
          <b-col md="3">
                <div>
                    <b-form-group label="Action" label-for="action">
                    <b-button variant="primary" @click="filterAllData"
                        ><feather-icon icon="filterIcons" /> Filter</b-button
                    >

                    <b-button variant="info" @click="resetAllData"  style="margin-left:12px"
                        ><feather-icon icon="filterIcons" /> Reset</b-button
                    >
                    </b-form-group>
                </div>
          </b-col>
         
          </b-row>

 
          <br />

          <b-row>
      
        <b-col md="2" sm="4" xs="4">
                   
          <div class="d-flex flex-wrap" style="float: left;margin-left: -23px;">
            <b-form-group
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
            </b-form-group>

            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
          </div>
            
            </b-col>
            <b-col md="7">

            </b-col>
            <b-col md="3" sm="8" xs="6">

              <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="md"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group>
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                  
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            </b-col>
            </b-row>
          <!-- </div> -->

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <!-- <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination> -->
            </div>
          </b-card-body>
        </b-card-body>

        <!-- <b-overlay :show="showUpdate" rounded="sm"> -->

<b-table
  striped
  hover
  responsive
  class="position-relative"
  :current-page="currentPage"
  :items="history_list"
  :fields="fields"
  :sort-by.sync="sortBy"
  :sort-desc.sync="sortDesc"
  :sort-direction="sortDirection"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
  <template #cell(action)="data">
    <b-button @click="SelectedVehicleDefects(data.item.id)"
      >View Defects</b-button
    >
  </template>
</b-table>
<!-- </b-overlay> -->
      </b-card>
    <div class="hiddenContainer"></div>

    <b-modal
      size="lg"
      id="modal-center"
      centered
      title="Vehicle Defects"
      ok-only
      ok-title="Cancel"
      ref="modal-center"
    >
      <b-row v-for="(Citem, Cindex) in category_data" :key="Cindex">
        <h5 class="defect-show-title">{{ Citem.title }}</h5>

        <b-col
          cols="12"
          md="2"
          v-for="(item, index) in CategoryWiseData(Citem.id)"
          :key="index"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="selected"
              :value="item.id"
              :name="item.title"
              :disabled="true"
              >{{ item.title }}
              <div v-if="selected.includes(item.id) > 0">
                <b-link v-if="validateIcon(item.id, 1)">
                  <feather-icon
                    icon="FileTextIcon"
                    size="21"
                    @click="ViewDefectDetails(item.id, 1)"
                  />
                </b-link>

                <b-link v-if="validateIcon(item.id, 2)">
                  <feather-icon
                    icon="VideoIcon"
                    size="21"
                    @click="ViewDefectDetails(item.id, 2)"
                  />
                </b-link>

                <b-link v-if="validateIcon(item.id, 3)">
                  <feather-icon
                    icon="MailIcon"
                    size="21"
                    @click="ViewDefectDetails(item.id, 3)"
                  />
                </b-link>
              </div>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-message"
      centered
      title="Message"
      ok-only
      ok-title="Cancel"
      ref="modal-message"
    >
      {{ MessageDetails }}
    </b-modal>
  </b-card>
</template>
  
  <script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BTable,
  BFormCheckbox,
  BPagination,
  BOverlay,
  BFormInvalidFeedback,
  BDropdownItem,
  BDropdown,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
// import 'vue-good-table/dist/vue-good-table.css'
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormCheckbox,
    BPagination,
    vSelect,
    BOverlay,
    BFormInvalidFeedback,
    BDropdownItem,
    BDropdown,
    FeatherIcon,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      pdfView:'',
      MessageDetails: "",
      fileUrl: serverUri + `/upload/`,
      selected: [],
      selectedData: [],
      vehicle:[],
      driver: "",
      option: [],
      driver_options:[],
      category_data: [],
      showUpdate: false,
      show: false,
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      vehicle_defects: [],
      vehicle_defects_data: [],
      selectedDefects: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      history_list: [],
      filterOn: [],
      selectedDriver: "",
      selectedModalDvir: "",
      startDate: "",
      endDate: "",
      companyId: getUserData().company_id,
      fields: [
        {
          key: "driver_name",
          label: "Driver Name",
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },

        {
          key: "tractor_id",
          label: "Vehicle",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "odometer",
          label: "Odometer",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "remarks",
          label: "Remarks",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },
  mounted() {
    this.getCategories();
    this.getVehicleDefects();
  },
  created() {

    axios.get(`${serverUri}/drivers/alldriversdata/${this.companyId}`).then((res) => {
            res.data.map((item) => {
                this.driver_options.push({ title: item.id, label: item.driver_name });
            })
        })

    this.getInspectionsList();
    axios.post(`${serverUri}/fleet/getfleet`,{
      company : this.companyId
    }).then((res) => {
            res.data.map((item) => {
                this.option.push({ title: item.id, label: item.tractor_id +' ('+item.model+'-'+item.year+')' });
            })
        })
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    validateIcon(id, type) {
      const Output = [];
      this.selectedData.map((item, index) => {
        if (item.id == id && type == 1) {
          if (item.file != "") {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              Output.push(true);
            }
          }
        } else if (item.id == id && type == 2) {
          const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
          const ext = item.file.split(".").pop();
          console.log(ext);
          if (imageExts.includes(ext)) {
            Output.push(true);
          }
        } else if (item.id == id && type == 3) {
          if (item.message != "") {
            Output.push(true);
          }
        }
      });

      return Output.length > 0 ? true : false;
    },

    // ViewDefectDetails()
    // {

    // },

    getInspectionsList() {
      axios
        .get(`${serverUri}/inspections_list/${this.companyId}`)
        .then((res) => {
          if (res.data) {
            this.history_list = res.data;
            this.showUpdate = false;

          }
        });
    },

    ViewDefectDetails(defectId, type) {
      const resultI = [];
      const resultV = [];
      const resultM = [];
      this.selectedDefects.map((item, index) => {
        if (
          this.selectedModalDvir == item.dvir_id &&
          item.vehicle_defect_id == defectId
        ) {
          if (type == 1) {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultI.push(this.fileUrl + item.file);
            }
          } else if (type == 2) {
            const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultV.push(this.fileUrl + item.file);
            }
          } else {
            if (item.message != "") {
              resultM.push(item.message);
            }
          }
        }
      });

      if (type == 1) {
        if (resultI.length == 0) {
          alert("No file found.");
          return false;
        }
        window.open(resultI[0], "_blank");
      } else if (type == 2) {
        if (resultV.length == 0) {
          alert("No video file found.");
          return false;
        }

        window.open(resultV[0], "_blank");
      } else {
        if (resultM.length == 0) {
          alert("No message found.");
          return false;
        } else {
          this.MessageDetails = resultM[0];
          this.$refs["modal-message"].show();
        }
      }
    },

    SelectedVehicleDefects(id) {
      // this.selectedDefects = data;
      // this.selectedModalDvir = id;
      this.$router.push({
        name: "apps-vehicle-inspections-pdf",params: {id:id}
      });

      //   this.selected = [];
      //   this.selectedData = [];
      // axios.get(`${serverUri}/inspections_defects_list/${id}`).then((res) => {
      //     if (res.data)
      //     {
      //       this.selectedDefects = res.data;

      //       this.selectedDefects.map((item, index) => {
      //         if(id == item.dvir_id)
      //         {
      //           this.selected.push(item.vehicle_defect_id)
      //           this.selectedData.push({file:item.file,message:item.message,id:item.vehicle_defect_id})
      //         }
      //       });

      //       console.log(this.selected);
      //     }
      //   });
    },
    CategoryWiseData(catId) {
      const AllData = [];
      this.vehicle_defects.map((item, index) => {
        if (catId == item.category_id && item.status == 1) {
          AllData.push(item);
        }
      });
      return AllData;
    },

    getCategories() {
      axios
        .get(
          `${serverUri}/inspections/vehicle_defects_category/${this.companyId}`
        )
        .then((res) => {
          res.data.map((item, index) => {
            this.category_data.push(item);
          });
        });
    },

    getVehicleDefects() {
      axios
        .get(`${serverUri}/inspections/vehicle_defects/${this.companyId}`)
        .then((res) => {
          res.data.map((item, index) => {
            this.vehicle_defects.push(item);
          });
        });
    },

    filterAllData() {

      const Vehicles = [];

      this.vehicle.map((item, index) => {
              Vehicles.push(item.title);
          });

          if(this.startDate == '')
          {
            this.showToast("warning", "Failure", 'Please select the start date');
            return false;
          }

          if(this.endDate == '')
          {
            this.showToast("warning", "Failure", 'Please select the end date');
            return false;

          }

          if(Vehicles.length == 0)
          {
            this.showToast("warning", "Failure", 'Please select vehicle');
            return false;

          }

      this.showUpdate = true;
      this.history_list = [];


      console.log(this.selectedDriver)

      axios
        .post(`${serverUri}/filter/inspections_list`,{
          company_id : this.companyId,
          vehicle_id : Vehicles,
          start_date : this.startDate,
          end_date   : this.endDate,
          driver     : this.selectedDriver == undefined || this.selectedDriver == '' ? '' : this.driver.title
        })
        .then((res) => {
          if (res.data.status) 
          {
            this.pdfView = res.data.pdf,
            this.history_list = res.data.data;
            this.showUpdate = false;

            let pdfWindow = window.open("");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.pdfView) + "'></iframe>");
          } else {

            this.history_list = [];

            this.showUpdate = false;

          }
        });
    },

    resetAllData()
    {
        this.showUpdate = true;
        this.startDate = '',
        this.endDate = '',
        this.vehicle = [],

        this.getInspectionsList();
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      // this.$nextTick(() => {
      //     this.trSetHeight(this.$refs.form.scrollHeight);
      // });
    },

    selectionChanged(params) {
      this.selectedItems = params.selectedRows;
    },

    changeYear() {
      this.getFilteredMMR();
    },

    changeMonth() {
      this.getFilteredMMR();
    },

    onChange() {
      // this.selectedDriver = this.driver?.title;
    },
    onChangeDriver() {
      this.selectedDriver = this.driver?.title;
    },
    exportPDF(data) {
      axios
        .post(`${serverUri}/fleet/getselectedmaintenance`, {
          tractor_id: data.formattedRow.tractor_id,
          domicile: data.formattedRow.domicile,
          month: moment().month(data.formattedRow.month).format("M"),
        })
        .then(async (res) => {
          var temp = await res.data;

          if (temp.length) {
            this.mmrData = {
              signature: this.signature,
              monthYear: this.selectedMonth + " " + this.selectedYear,
              domicile: data.formattedRow.domicile,
              serviceName: data.formattedRow.name,
              odometer: data.formattedRow.odometer
                ? Math.round(data.formattedRow.odometer)
                : "",
              tractor: data.formattedRow.tractor_id,
              mmrTask: data.formattedRow.mmr_task,
              oos: data.formattedRow.out_of_service,
              tArr: temp,
              dateCompleted: moment(this.selectedDate).format("MM/DD/YYYY"),
            };

            setTimeout(() => {
              this.$refs.contentToExport.save();
            }, 1000);
          }
        });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vgt-table th {
  font-size: 13px;
}

.hiddenContainer {
  position: absolute;
  left: -10000px;
  top: 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.modal-dialog-centered {
  max-width: 80%;
}
.defect-show-title {
  width: 100%;
  padding: 10px 15px;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
</style>
  